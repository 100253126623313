/**
 * All functionality needed to show a dealer page.
 *
 * @module feature/Dealer
 */
import 'bootstrap/js/dist/collapse.js';

/**
 * DealerPage custom scripting
 *
 * @requires bootstrap/js/dist/collapse
 * @memberof module:feature/Dealer
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class DealerPage {
    /**
     * Initializes a Dealer Page
     * @param {jQueryElement} $element jQueryDomElement which is upgraded with this functionality.
     */
    constructor($element) {
        this.$element = $($element);
        this.$infoBlock = this.$element.find('.js-basic-info');
        this.$infoBlockHeader = this.$infoBlock.find('.card__header');
        this.$infoBlockMap = this.$infoBlock.find('.card__image');
        this.$checkmarkList = $('.checkmarklist-container');
    }

    /**
     * Does basic checks needed.
     */
    init() {
        this.checkHeightBasicInfo();
    }

    /**
     * Checks height of basic info block.
     */
    checkHeightBasicInfo() {
        let infoBlockHeight = this.$infoBlock.outerHeight();
        let infoBlockHeaderHeight = this.$infoBlockHeader.outerHeight();
        let infoBlockMapHeight = this.$infoBlockMap.outerHeight();
        let checkmarkListHeight = infoBlockHeight - infoBlockHeaderHeight - infoBlockMapHeight;

        // IF submenu is not present on dealerpage
        if (!$('.sub-navigation').length) {
            this.$checkmarkList.css({ height: checkmarkListHeight });
        }
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: DealerPage,
    classAsString: 'DealerPage',
    cssClass: 'js-dealer-page',
});

export default DealerPage;
